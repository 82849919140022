import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sobre-nos",
      "style": {
        "position": "relative"
      }
    }}>{`Sobre nós`}</h1>
    <p>{`O Bridge é um laboratório integrado ao Centro Tecnológico (CTC) da Universidade Federal de Santa Catarina (UFSC), que atua na pesquisa e desenvolvimento de soluções tecnológicas conectando governo e cidadãos por meio da inovação.`}</p>
    <p><img parentName="p" {...{
        "src": "/image/team.jpg",
        "alt": "Bridge Team"
      }}></img></p>
    <h2 {...{
      "id": "o-que-valorizamos",
      "style": {
        "position": "relative"
      }
    }}>{`O que valorizamos`}</h2>
    <p>{`Como organização e indivíduos, respeitamos a sociedade, assim como colaboradores, parceiros, comunidade acadêmica, clientes e todos aqueles que impactamos. Trabalhamos para alcançar resultados com simplicidade, não deixando de lado a constante busca pela criatividade e inovação tecnológica. Nosso compromisso é contribuir positivamente com a sociedade, dando sempre valor à ética e transparência em nossos projetos. Temos acima de tudo paixão e compromisso com as pessoas e a tecnologia. Com humildade, assumimos desafios encorajando toda a equipe a trabalhar de forma unida, para atingir a mais alta qualidade, agilidade e excelência.`}</p>
    <h2 {...{
      "id": "nosso-processo-de-desenvolvimento",
      "style": {
        "position": "relative"
      }
    }}>{`Nosso processo de desenvolvimento`}</h2>
    <p>{`Realizamos uma extensa busca de referências para desenvolver nossos componentes e fomos inspirados por diversos `}<em parentName="p">{`design systems`}</em>{`, principalmente o `}<a href="https://www.carbondesignsystem.com/">{`Carbon Design System`}</a>{` e o `}<a href="https://atlassian.design/">{`Atlassian Design`}</a>{`, além das recomendações de usabilidade do `}<a href="https://www.nngroup.com">{` NN Group`}</a>{`.
Durante o estágio de desenvolvimento, também discutimos os componentes com desenvolvedores e analistas de negócios de vários produtos do Laboratório Bridge, com o objetivo de cobrir o maior número possível de casos de uso. `}<strong parentName="p">{`Os componentes e resultados desse projeto também foram testados com usuários para medir e atender os critérios de usabilidade.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      